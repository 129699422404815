<template>
  <div>
    <div class="row" style="margin-bottom: 80px">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 text-center">
        <h5 class="ma-28-semi text-green text-uppercase m-0">
          {{ $t("guideline-title-1") }}
        </h5>
        <span class="in-16-re">
          {{ $t("guideline-description-1") }}
        </span>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6 pb-5">
          <div class="content-card" @click="handleClick('overview')">
            <div class="img-container">
              <img src="@/assets/image/bg/bg_19.png" height="256px" />
            </div>
            <h4 class="m-3 ma-24-semi text-green">{{ $t("overview-tab") }}</h4>
            <div class="px-3">
              <div class="separator"></div>
            </div>
            <p class="m-24">
              {{ $t("overview-content") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="content-card" @click="handleClick('featuretreedetail')">
            <div class="img-container">
              <img src="@/assets/image/bg/bg_18.png" height="256px" />
            </div>
            <h4 class="m-3 ma-24-semi text-green">{{ $t("topic-tab") }}</h4>
            <div class="px-3">
              <div class="separator"></div>
            </div>
            <p class="m-24">
              {{ $t("topic-tab-content") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="content-card" @click="handleClick('manual')">
            <div class="img-container">
              <img src="@/assets/image/bg/img_5.png" height="256px" />
            </div>
            <h4 class="m-3 ma-24-semi text-green">{{ $t("guide-tab") }}</h4>
            <div class="px-3">
              <div class="separator"></div>
            </div>
            <p class="m-24">
              {{ $t("guide-tab-content") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row" style="margin-bottom: 80px; margin-top: 72px">
        <div class="col-lg-2 col-md-1"></div>
        <div class="col-lg-8 col-md-9 text-center">
          <h5 class="ma-28-semi text-green m-0">
            {{ $t("guideline-title-2") }}
          </h5>
          <span class="in-16-re">
            {{ $t("guideline-description-2") }}
          </span>
        </div>
        <div class="col-lg-2 col-md-1"></div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div v-for="e,i in blogList" :key="i" class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick(e.link)">
              <div class="img-container">
                <img :src="e.img" height="256px" />
              </div>
              <h4 class="m-3 ma-24-semi text-green">{{ e.title }}</h4>
              <div class="px-3">
                <div class="separator"></div>
              </div>
              <p class="m-24">
                {{ e.content }}
              </p>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-station')">
              <div class="img-container">
                <img src="@/assets/image/bg/bg_19.png" height="256px" />
              </div>
              <h4 class="m-3 ma-24-semi text-green">
                {{ $t("feature-info") }}
              </h4>
              <div class="px-3">
                <div class="separator"></div>
              </div>
              <p class="m-24">
                {{ $t("feature-info-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-schedule')">
              <div class="img-container">
                <img src="@/assets/image/bg/bg_22.png" height="256px" />
              </div>
              <h4 class="m-3 ma-24-semi text-green">{{ $t("app") }}</h4>
              <div class="px-3">
                <div class="separator"></div>
              </div>
              <p class="m-24">
                {{ $t("app-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-router')">
              <div class="img-container">
                <img src="@/assets/image/bg/bg_22.png" height="256px" />
              </div>
              <h4 class="m-3 ma-24-semi text-green">
                {{ $t("route-by-station") }}
              </h4>
              <div class="px-3">
                <div class="separator"></div>
              </div>
              <p class="m-24">
                {{ $t("route-by-station-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('router-info')">
              <div class="img-container">
                <img src="@/assets/image/bg/bg_21.png" height="256px" />
              </div>
              <h4 class="m-3 ma-24-semi text-green">{{ $t("router-info") }}</h4>
              <div class="px-3">
                <div class="separator"></div>
              </div>
              <p class="m-24">
                {{ $t("router-info-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-schedule')">
              <div class="img-container">
                <img src="@/assets/image/bg/bg_19.png" height="256px" />
              </div>
              <h4 class="m-3 ma-24-semi text-green">
                {{ $t("schedule-search") }}
              </h4>
              <div class="px-3">
                <div class="separator"></div>
              </div>
              <p class="m-24">
                {{ $t("schedule-search-content") }}
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blogList: [
        {
          img: "/assets/image/bg/bg_19.png",
          title: this.$t("overview"),
          link: "overview",
          content: this.$t("overview-content"),
        },
        {
          img: "/assets/image/bg/bg_22.png",
          title: this.$t("app"),
          link: "app",
          content: this.$t("app-content"),
        },
        {
          img: "/assets/image/bg/img_7.png",
          title: this.$t("manual-guide"),
          link: "manual",
          content: this.$t("feature-info-content"),
        },
        {
          img: "/assets/image/bg/bg_22.png",
          title: this.$t("feature-info2"),
          link: "featureplanning",
          content: this.$t("feature-info2-content"),
        },
        {
          img: "/assets/image/bg/bg_21.png",
          title: this.$t("feature-info3"),
          link: "blog",
          content: this.$t("feature-info3-content"),
        },
        {
          img: "/assets/image/bg/bg_19.png",
          title: this.$t("mean"),
          link: "blog2",
          content: this.$t("mean-content"),
        },
        // {
        //   img: "/assets/image/bg/bg_19.png",
        //   title: this.$t("feature-tree-info"),
        //   link: "featuretreedetail",
        //   content: this.$t("feature-tree-info-content"),
        // },
        // {
        //   img: "/assets/image/bg/bg_19.png",
        //   title: this.$t("feature-tree-planning"),
        //   link: "featuretreeplanning",
        //   content: this.$t("feature-tree-info-content"),
        // },
      ],
    };
  },
  methods: {
    handleClick(route) {
      this.$router.push(`/guideline/${route}`);
    },
  },
};
</script>

<style scoped>
.content-card:hover {
  border: 1px solid var(--hover, #063);
}
.content-card:hover .img-container img {
  transform: scale(1.1); /* Phóng to lên 110% */
}
.img-container {
  display: inline-block; /* hoặc block tùy vào cấu trúc của bạn */
  overflow: hidden;
}

.img-container img {
  transition: transform 0.5s ease; /* Tạo hiệu ứng mềm mại */
}

.img-container:hover img {
  transform: scale(1.1); /* Phóng to lên 110% */
}
.separator {
  width: 100%;
  height: 1px;
  background-color: #eaecea;
}
.m-24 {
  margin: 24px;
}
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  /* border-radius: 24px; */
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
  /* height: 477px; */
}
.content-card h4 {
  font-size: 24px !important;
  font-weight: 500;
}
.content-card p {
  max-height: 200px !important;
  text-overflow: ellipsis;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Số dòng tối đa */
  -webkit-box-orient: vertical;
}
</style>
